
import request from '@/router/axios'
import qs from 'qs'
import store from '@/store'
import {getStore, setStore} from "@/util/store.js";
import website from "@/const/website.js";
import router from "@/router";

const scope = 'server';
//const deviceId = '';

export const loginByUsername = (username, password) => {
  let grant_type = 'password'
  let dataObj = qs.stringify({'username': username, 'password': password})
  let basicAuth = 'Basic ' + window.btoa(website.smsLoginClient)
  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': getStore({name: 'tenantId'}),
      'Authorization': basicAuth
    },
    method: 'post',
    params: { grant_type},
    data: dataObj
  })
}

export const refreshToken = (refresh_token) => {
  const grant_type = 'refresh_token'
  // 获取当前选中的 basic 认证信息
  let basicAuth = getStore({name: 'basicAuth'})

  return request({
    url: '/auth/oauth/token',
    headers: {
      'isToken': false,
      'TENANT-ID': getStore({name: 'tenantId'}),
      'Authorization': basicAuth
    },
    method: 'post',
    params: {refresh_token, grant_type, scope}
  })
}

export const loginByMobile = (mobile, code) => {
  const grant_type = 'mobile'
  let basicAuth = 'Basic ' + window.btoa(website.smsLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': getStore({name: 'tenantId'}),
      'Authorization': basicAuth
    },
    method: 'post',
    params: {mobile: 'SMS@' + mobile, code: code, grant_type, scope}
  })
}

export const loginBySocial = (state, code) => {
  const grant_type = 'mobile'
  let basicAuth = 'Basic ' + window.btoa(website.socialLoginClient)

  // 保存当前选中的 basic 认证信息
  setStore({
    name: 'basicAuth',
    content: basicAuth,
    type: 'session'
  })

  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': getStore({name: 'tenantId'}),
      'Authorization': basicAuth
    },
    method: 'post',
    params: {mobile: state + '@' + code, code: code, grant_type, scope}
  })
}

export const getUserInfo = () => {
  return request({
    url: '/tenant/user/info',
    method: 'get'
  })
}

export const logout = () => {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

/**
 * 校验令牌，若有效期小于半小时自动续期
 * @param refreshLock
 */
export const checkToken = (refreshLock, $store) => {
  const token = store.getters.access_token
  if(token!=undefined && token!="") {
    // 获取当前选中的 basic 认证信息
    let basicAuth = getStore({name: 'basicAuth'})

    request({
      url: '/auth/oauth/check_token',
      headers: {
        isToken: false,
        Authorization: basicAuth
      },
      method: 'get',
      params: {token}
    }).then(response => {
      const expire = response && response.data && response.data.exp
      if (expire) {
        const expiredPeriod = expire * 1000 - new Date().getTime()
        //小于半小时自动续约
        if (expiredPeriod <= 30 * 60 * 1000) {
          if (!refreshLock) {
            refreshLock = true
            $store.dispatch('RefreshToken')
                .catch(() => {
                  clearInterval(this.refreshTime)
                })
            refreshLock = false
          }
        }
      }
    }).catch(error => {
      console.error(error)
    })
  }else {
    router.push({
        path: '/login'
      });
  }
}

/**
 * 注册用户
 */
export const registerUser = (userInfo) => {
  return request({
    url: '/tenant/register/user',
    method: 'post',
    data:userInfo
  })
}
