<!-- 库存 -->
<template>
  <div class="mine-section">
    <div class="back-header">
      <div @click="backHandler">
          <div class="account">
              <div class="line line3" style="height: 30px;" >
                  <div class="icon" ></div>
                  <van-cell  />
              </div>
          </div>
      </div>
    </div>
    <!-- 库存 -->
    <div class="kc">
      <van-tree-select :items="itemsList" :main-active-index.sync="active">
        <template #content>
          <div class="header">
            <van-row>
              <van-col span="4">
                货道号
              </van-col>
              <van-col span="3">
                图片
              </van-col>
              <van-col span="5">
                商品名称
              </van-col>
              <van-col span="5">
                编号/规格
              </van-col>
              <van-col span="7">
                预警值/数量
              </van-col>
            </van-row>
          </div>
          <div class="container" v-for="(item, index) in itemsList" v-if="active === index">
            <van-row v-for="(v, i) in item.deviceChannelList" :key="i">
              <van-col span="4">
                <img v-if="item.warningStatus==1" :src="warningImg" alt="">
                {{ v.chanNo }}
              </van-col>
              <van-col span="3">
                <img :src="v.picture=='' ? image :url+v.picture"  alt="" style="height: 32px;">
              </van-col>
              <van-col span="5">
                {{ v.goodsName }}
              </van-col>
              <van-col span="5">
                {{ v.sku }}/{{ v.specification }}
              </van-col>
              <van-col span="7">
                {{ v.thresholdLow }}/{{ v.number }}
              </van-col>
            </van-row>
          </div>
        </template>
      </van-tree-select>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import {checkToken} from '@/api/login'
import {mapState} from "vuex";
import {channel} from "@/api/admin/user";
export default {
  components: {
    Header,
  },
  data () {
    return {
      // 刷新token锁
      refreshLock: false,
      url: 'http://nvmi.gyx360.com',
      // 刷新token的时间
      refreshTime: '',
      warningImg: 'images/warning.png', //感叹号路径
      image: 'images/imgs.png', //图片路径
      // 计时器
      timer: '',
      item: '',
      back: '<',
      active: 0,
      itemsList: [
      ],
    };
  },
  mounted () {
      //获取页面传递过了的值
      this.item = this.$route.query.item;
  },
  activated () {
      this.item = this.$route.query.item;
      this.channel()
  },
  created() {

      // 更新sessionStore 权限信息
      this.$store.dispatch('GetUserInfo')
      // 实时检测刷新token
      this.refreshToken()
      //获取页面传递过了的值
      this.item = this.$route.query.item;
      this.channel()
  },
  destroyed() {
      clearInterval(this.refreshTime)
      clearInterval(this.timer)
  },
  computed: {
      ...mapState({
          userInfo: (state) => state.user.userInfo,
      }),
  },
  methods: {
    channel(){
        console.log(this.item.deviceId)
        if(this.item.deviceId!=undefined){
            channel(this.item.deviceId).then(resp => {
              if (resp.data.code === 0) {
                  if(resp.data.data!=null){
                      this.itemsList=resp.data.data;
                  }
              }
          }).catch(() => {
          })
        }
    },
    backHandler () {
      this.$router.go(-1);
    },
    // 实时检测刷新token
    refreshToken() {
        this.refreshTime = setInterval(() => {
            checkToken(this.refreshLock, this.$store)
        }, 60000)
    }
  },
};
</script>

<style scoped lang="scss">
.account {
  height: 22%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // background-color: brown;
  .line {
    width: 100%;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 .3rem;

    .icon {
      height: .6rem;
      width: .6rem;
      background-image: url('../../assets/images/mine-setting.png');
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .line2 {
    .icon {
      background-image: url('../../assets/images/mine-password.png');
    }
  }

  .line3 {
    .icon {
      background-image: url('../../assets/images/back.png');
    }
  }
}
.mine-section {
  height: 100%;
  width: 100%;
}

.back-header {
  height: 5%;
  background-color: #EEEEEE;
  display: contents;
  align-items: center;
  padding-left: .5rem;
}

.kc {
  height: 90%;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;

  .header {
    font-size: .35rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #263DC5;
    // height: .8rem;
    line-height: .8rem;

    // width: 378px;
    // height: 41px;
    background: rgba(19, 71, 255, 0.13);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .container {
    height: 100%;
    width: 100%;
    // background-color: #5466FF;
  }



}


/deep/ .van-cell {
  font-size: .40rem;
}

/deep/ .van-button--block {
  font-size: .4rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  width: 90%;
  background: linear-gradient(137deg, #5466FF 0%, #63A2FD 100%);
  border-radius: 46px 46px 46px 46px;
  opacity: 1;
}

/deep/ .van-tree-select {
  height: 100% !important;
  width: 100% !important;
}

/deep/ .van-tree-select__nav {
  flex: unset !important;
  width: 15%;
}

/deep/ .van-tree-select__content {
  flex: unset !important;
  width: 85%;
}

/deep/ .van-col {
  text-align: center;
  word-break: break-all;
  padding: 0 .1rem 0 .1rem;
}
/deep/ .van-row{
  border-bottom: 1px solid #c0c0c0;
  // height: 1rem;
  // line-height: 1rem;
  padding: .2rem .1rem .2rem 0;
}



</style>
