

import request from '@/router/axios'

export function fetchList(query) {
  return request({
    url: '/admin/user/page',
    method: 'get',
    params: query
  })
}

export function addObj(obj) {
  return request({
    url: '/admin/user',
    method: 'post',
    data: obj
  })
}

export function getObj(id) {
  return request({
    url: '/admin/user/' + id,
    method: 'get'
  })
}

export function delObj(id) {
  return request({
    url: '/admin/user/' + id,
    method: 'delete'
  })
}

export function putObj(obj) {
  return request({
    url: '/admin/user',
    method: 'put',
    data: obj
  })
}

export function getDetails(obj) {
  return request({
    url: '/admin/user/details/' + obj,
    method: 'get'
  })
}

// 更改个人信息
export function editInfo(obj) {
  return request({
    url: '/admin/user/edit',
    method: 'put',
    data: obj
  })
}

// 更改个人信息
export function editInfoApi(obj) {
  return request({
    url: '/tenant/user/editApi',
    method: 'put',
    data: obj
  })
}


//查询设备信息
export function myDeviceApi() {
  return request({
    url: '/tenant/device/myDeviceApi',
    method: 'get',
  })
}

// 查询领用情况
export function stockLogDetailApi(type) {
  return request({
    url: '/tenant/stocklog/detail/stockLogDetailApi/?type=' + type,
    method: 'get'
  })
}

export function stockLogDetailWeekApi(type) {
  return request({
    url: '/tenant/stocklog/detail/stockLogDetailWeekApi/?type=' + type,
    method: 'get'
  })
}


export function myDeviceStockApi(query) {
  return request({
    url: '/tenant/device/myDeviceStockApi' ,
    method: 'get',
    params: query
  })
}

export function channel(deviceId) {
  return request({
    url: '/tenant/device/channelApi/' +deviceId,
    method: 'get',
  })
}

export function fetchJgList(query) {
  return request({
    url: '/device/channel/alert/page',
    method: 'get',
    params: query
  })
}
