import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import common from './modules/common'
import tags from './modules/tags'
import logs from './modules/logs'
import createPersistedState from "vuex-persistedstate"
import login from './login/index'
import home from './home/index'
import getters from './getters'

Vue.use(Vuex)

const state = {

}

const mutations = {

}

const actions = {

}



const modules = {
  login,
  home,
  user,
  common,
  logs,
  tags
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
  plugins: [createPersistedState({ storage: window.sessionStorage })],
})

export default store
