<template>
  <div class="login-section">
    <div class="bg"></div>
    <!-- <van-button class="submit-btn" block type="info" @click="handleLogin">登录</van-button> -->
    <van-form @submit="onSubmit" style="width: 80%">
      <van-field v-model="loginForm.username" name="用户名" label="" placeholder="请输入用户名" />
      <van-field v-model="loginForm.password" type="password" name="密码" label="" placeholder="请输入密码" />
      <div style="margin-top: 16px">
        <van-button round block type="info" native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Toast } from 'vant';
import {mapGetters} from "vuex";
import store from "@/store";
export default {
  name: '',
  components: {},
    computed: {
        ...mapGetters(["tagWel","website"]),
    },
  data () {
    return {
      username: '',
      password: '',
      loginForm: {
        username: "",
        password: "",
      }
    }
  },
  activated () {
      //跳转到登录页面清空页面缓存
      this.loginForm.username ="";
      this.loginForm.password ="";
  },
  created() {
  },
  methods: {
    onSubmit () {
      if (this.loginForm.username == '') {
        Toast({
          message: '请输入用户名',
          position: 'top',
        });
        return;
      }
      if (this.loginForm.password == '') {
        Toast({
          message: '请输入密码',
          position: 'top',
        });
        return;
      }
      //登录
      this.loginByUsername()
    },
    loginByUsername() {
        this.$store.dispatch("LoginByUsername", Object.assign({}, this.loginForm)).then(() => {
            const type = store.getters.type
            //判断登录人信息是否是管理员，不是管理员退出登录，并提示没有登录权限。
            if(type!=0){
                Toast.fail({
                  message: "无权登录,需要管理员权限",
                  position: 'top',
                  icon: 'bulb-o'
              });
                //退出登录
                this.$store.dispatch('LogOut').then(() => {
                    this.$router.replace({
                        path: '/login'
                    });
                })

            }else{
                //登录成功获取登录人信息
                this.$router.push({path: '/home'});
            }

        });
    },
    handleLogin () {
      this.$router.replace({
        path: '/home',
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../assets/style/variable.scss';

.login-section {
  height: 100%;
  @include flexCenter;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .submit-btn {
    width: 500px;
  }
}

.bg {
  background-image: url('../../../public/images/login-bg.png');
  width: 100%;
  height: 30%;
  background-repeat: no-repeat;
  background-size: 100% 60%;
}

/deep/ .van-cell {
  border: 1px solid #bcbcbe;
  border-radius: 30px;
  margin-top: 10px;
}
</style>
