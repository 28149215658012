<template>
  <div class="mine-section">
<!--    <div class="header">
      <div>X</div>
    </div>-->
    <!-- 背景图 -->
    <div class="card header-bg">
      <div class="header">
          <img :src="this.userInfo.avatar==null ? image :url+this.userInfo.avatar" style="width: 25px;">
      </div>
      <span class="name" style="margin-top: .2rem;">{{this.userInfo.username}}</span>
    </div>
    <!-- 账号和修改密码 -->
    <div class="account">
      <div class="line line1">
        <div class="icon"></div>
        <van-cell  title="账户设置" />
      </div>
      <div class="line line2">
        <div class="icon"></div>
        <van-cell is-link title="修改密码" @click="changePassword" />
      </div>
    </div>
    <!-- 退出登录 -->
    <div class="log-out">
      <van-button round block @click="logout">退出登录</van-button>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { Dialog } from 'vant';
import {checkToken} from '@/api/login'
import {mapState} from "vuex";
export default {
  components: {
    Header,
  },
  data () {
    return {
        // 刷新token锁
        refreshLock: false,
        // 刷新token的时间
        refreshTime: '',
        url: 'http://nvmi.gyx360.com',
        image: 'images/mine.png', //图片路径
        // 计时器
        timer: '',
        username: ''
    };
  },
  mounted () {

  },
  activated () {
      // 更新sessionStore 权限信息
      this.$store.dispatch('GetUserInfo')
      // 实时检测刷新token
      this.refreshToken()
  },
    created() {

    },
    destroyed() {
        clearInterval(this.refreshTime)
        clearInterval(this.timer)
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
  methods: {
    //跳转到修改密码页面
    changePassword () {
      this.$router.push("/changepassword");
    },
    logout() {
        Dialog.confirm({
            title: '提示',
            message: '是否退出系统?',
        }).then(() => {
          this.$store.dispatch('LogOut').then(() => {
             // this.$router.push({ path: '/login' })
             /* this.$router.push({ path: '/home'});*/
               this.$router.push({
                  path: '/login'
               });
          })
      }).catch(() => {
          // on cancel
      });

      },
      // 实时检测刷新token
      refreshToken() {
          this.refreshTime = setInterval(() => {
              checkToken(this.refreshLock, this.$store)
          }, 60000)
      }
  },
};
</script>

<style scoped lang="scss">
.mine-section {
  height: 100%;
  width: 100%;
}

.header {
  height: 5%;
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
  padding-left: .5rem;
}

.card {
  height: 30%;
  width: 100%;
  // background-color:cornflowerblue;
  background-image: url('../../assets/images/mine-header-bg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .header {
    width: 15%;
    height: 26%;
    // background-color: aqua;
    border-radius: 50%;
    margin-top: -1.0rem;
   // background-image: url('../../assets/images/mine.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .name {
    font-size: .45rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #141414;
  }
}

.account {
  height: 25%;
  width: 100%;

  // background-color: brown;
  .line {
    width: 100%;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 .3rem;

    .icon {
      height: .6rem;
      width: .6rem;
      background-image: url('../../assets/images/mine-setting.png');
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .line2 {
    .icon {
      background-image: url('../../assets/images/mine-password.png');
    }
  }
}

.log-out {
  width: 100%;
  height: 10%;
  // background-color: blueviolet;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .van-cell {
  font-size: .40rem;
}

/deep/ .van-button--block {
  font-size: .4rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #454545;
  width: 90%;
}</style>
