<template>
  <div class="mine-section">
    <div class="header">
      <div @click="backHandler">
          <div class="account">
              <div class="line line3" style="height: 30px;" >
                  <div class="icon" ></div>
                  <van-cell  />
              </div>
          </div>

      </div>
    </div>
    <!-- 账号和修改密码 -->
    <div class="account">
      <van-field v-model="inputData.password" label="旧密码" type="password" placeholder="填写旧密码" />

      <van-field v-model="inputData.newpassword1" label="新密码"  type="password" placeholder="填写新密码"  />

      <van-field v-model="inputData.newpassword2" label="确认密码" type="password" placeholder="重新确认密码"  />
    </div>
    <!-- 退出登录 -->
    <div class="log-out">
      <van-button round block @click="submitForm()">确认修改</van-button>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import {checkToken} from '@/api/login'
import {mapState} from "vuex";
import {editInfoApi} from "@/api/admin/user";
import { Toast } from 'vant';
export default {
  components: {
  },
  data () {
    return {
      back: '<',
      inputData: {
          password: "",
          newpassword1: "",
          newpassword2: "",
          userId: "",
          username: "",
      },
        // 刷新token锁
        refreshLock: false,
        // 刷新token的时间
        refreshTime: '',
        // 计时器
        timer: ''
    };
  },
  mounted () {

  },
  activated () {
      // 更新sessionStore 权限信息
      this.$store.dispatch('GetUserInfo')
      // 实时检测刷新token
      this.refreshToken()
      //跳转到登录页面清空页面缓存
      this.inputData.password ="";
      this.inputData.newpassword1 ="";
      this.inputData.newpassword2 ="";
  },

  created() {

  },
  destroyed() {
      clearInterval(this.refreshTime)
      clearInterval(this.timer)
  },
  computed: {
      ...mapState({
          userInfo: (state) => state.user.userInfo,
      }),
  },

  methods: {
      submitForm() {
          if (this.inputData.password == '') {
              Toast({
                  message: '请输入旧密码',
                  position: 'top',
              });
              return;
          }else if(this.inputData.password.length<6){
              Toast({
                  message: '旧密码长度不足',
                  position: 'top',
              });
              return;
          }

          if (this.inputData.newpassword1 == '') {
              Toast({
                  message: '请输入新密码',
                  position: 'top',
              });
              return;
          }else if(this.inputData.newpassword1.length<6){
              Toast({
                  message: '新密码长度不足6位',
                  position: 'top',
              });
              return;
          }

          if (this.inputData.newpassword2 == '') {
              Toast({
                  message: '请输入确认密码',
                  position: 'top',
              });
              return;
          }

          if(this.inputData.newpassword1!=this.inputData.newpassword2){
              Toast({
                  message: '新密码和确认密码不一致',
                  position: 'top',
              });
              return;
          }

          editInfoApi(this.inputData).then((res) => {
              if (res.data.data==true) {
                  Toast.success({
                      message: '密码修改成功',
                      position: 'top',
                  });
                  // 修改后注销当前token,重新登录
                  this.$store.dispatch("LogOut").then(() => {
                      this.$router.replace({
                          path: '/login'
                      });
                  });
              } else if(res.data.data==null) {
                  Toast.success({
                      message: res.data.msg,
                      position: 'top',
                  });
              }

          });
      },
    backHandler () {
      this.$router.go(-1);
    },
    // 实时检测刷新token
    refreshToken() {
        this.refreshTime = setInterval(() => {
            checkToken(this.refreshLock, this.$store)
        }, 60000)
    }
  },

};
</script>

<style scoped lang="scss">

.mine-section {
  height: 100%;
  width: 100%;
}

.header {
  height: 5%;
  background-color: #EEEEEE;
  display: contents;
  align-items: center;
  padding-left: .5rem;
}

.card {
  height: 30%;
  width: 100%;
  // background-color:cornflowerblue;
  background-image: url('../../assets/images/mine-header-bg.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .header {
    width: 15%;
    height: 26%;
    // background-color: aqua;
    border-radius: 50%;
    margin-top: -1.0rem;
    background-image: url('../../assets/images/mine.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .name {
    font-size: .45rem;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #141414;
  }
}

.account {
  height: 22%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  // background-color: brown;
  .line {
    width: 100%;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 .3rem;

    .icon {
      height: .6rem;
      width: .6rem;
      background-image: url('../../assets/images/mine-setting.png');
      background-position: top;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .line2 {
    .icon {
      background-image: url('../../assets/images/mine-password.png');
    }
  }

  .line3 {
    .icon {
      background-image: url('../../assets/images/back.png');
    }
  }
}

.log-out {
  width: 100%;
  height: 10%;
  // background-color: blueviolet;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .van-cell {
  font-size: .40rem;
}

/deep/ .van-button--block {
  font-size: .4rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
  width: 90%;
background: linear-gradient(137deg, #5466FF 0%, #63A2FD 100%);
border-radius: 46px 46px 46px 46px;
opacity: 1;
}
</style>
