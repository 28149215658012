<template>
    <div class="child-section">
        <Header>
            <div class="header-content" >
                <div class="header-title">
                    子页面
                </div>
                <div class="header-back">
                    <div class="back" @click="handleTitleBack">
                        <i class="iconfont icon-arrowthin"></i>
                    </div>
                </div>
            </div>
        </Header>
    </div>
</template>

<script>
import Header from "../../components/header.vue";
import {checkToken} from '@/api/login'
import {mapState} from "vuex";
export default {
    name:'',
    components: {
        Header
    },
    data() {
        return {
            // 刷新token锁
            refreshLock: false,
            // 刷新token的时间
            refreshTime: '',
            // 计时器
            timer: ''
        }
    },
    activated () {
        // 更新sessionStore 权限信息
        this.$store.dispatch('GetUserInfo')
        // 实时检测刷新token
        this.refreshToken()
    },
    created() {

    },
    destroyed() {
        clearInterval(this.refreshTime)
        clearInterval(this.timer)
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
    methods: {
        handleTitleBack(){
            this.$router.back()
        },
        // 实时检测刷新token
        refreshToken() {
            this.refreshTime = setInterval(() => {
                checkToken(this.refreshLock, this.$store)
            }, 60000)
        }
    }
}
</script>
<style scoped lang="scss">

</style>
