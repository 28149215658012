<template>
  <div class="index-section">
<!--    <div class="header">
      <div>X</div>
    </div>-->
    <div class="up">
      <span class="title">设备监控</span>
      <div class="up-container">
        <div class="card card1">
          <div class="card-img"></div>
          <div class="card-text-num">
            <span class="num">{{ deviceMonitoringData.all }}</span>
            <span class="text">所有设备</span>
          </div>
        </div>
        <div class="card card2">
          <div class="card-img"></div>
          <div class="card-text-num">
            <span class="num">{{ deviceMonitoringData.online }}</span>
            <span class="text">在线设备</span>
          </div>
        </div>
        <div class="card card3">
          <div class="card-img"></div>
          <div class="card-text-num">
            <span class="num">{{ deviceMonitoringData.offline }}</span>
            <span class="text">离线设备</span>
          </div>
        </div>
        <div class="card card4">
          <div class="card-img"></div>
          <div class="card-text-num">
            <span class="num">{{ deviceMonitoringData.stop }}</span>
            <span class="text">停用设备</span>
          </div>
        </div>
      </div>
    </div>
    <div class="down">
      <div class="card card1">
        <div class="title">
          今日领用
        </div>
        <div class="container">
          <div class="item item-left">
            <div class="num">
              <span>{{ receiveData.totalQuantity }}</span>
              <span>(个)</span>
            </div>
            <div class="text">
              商品领取总量
            </div>
          </div>
          <div class="item item-right">
            <div class="num">
              <span>{{ receiveData.totalAmount }}</span>
              <span>(元)</span>
            </div>
            <div class="text">
              商品领取总价
            </div>
          </div>
        </div>
      </div>
      <div class="card card2">
        <div class="title">
          昨日领用
        </div>
        <div class="container">
          <div class="item item-left">
            <div class="num">
              <span>{{ receiveData.yesterdaySalesQuantity }}</span>
              <span>(个)</span>
            </div>
            <div class="text">
              商品领取总量
            </div>
          </div>
          <div class="item item-right">
            <div class="num">
              <span>{{ receiveData.yesterdaySalesAmount }}</span>
              <span>(元)</span>
            </div>
            <div class="text">
              商品领取总价
            </div>
          </div>
        </div>
      </div>
      <div class="card card3">
        <div class="title">
          预警通知
        </div>
        <div class="container">
          <van-row class="row" v-for="(item, index) in alertData">
            <van-col span="5" style="text-align: center;">
              <div style="overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                <span class="alert-text" v-if="item.alertType==0">缺货告警</span>
                <span class="alert-text" v-if="item.alertType==1">超载告警</span>
              </div>
            </van-col>

              <van-col span="5" style="text-align: center;">
              <div style="overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                <span class="alert-text">{{ item.goodsName }}</span>
              </div>
            </van-col>
            <van-col span="10" style="text-align: center;">
              <span class="alert-text">{{ item.createTime }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {checkToken} from '@/api/login'
import {mapState} from "vuex";
import {myDeviceApi,stockLogDetailApi,fetchJgList} from "@/api/admin/user";
export default {
  components: {
  },
  data () {
    return {
        // 刷新token锁
        refreshLock: false,
        // 刷新token的时间
        refreshTime: '',
        // 计时器
        timer: '',
      deviceMonitoringData: {
        "all": 0,
        "online": 0,
        "offline": 0,
        "stop": 0
      },
      receiveData: {
          totalQuantity: 0,
          totalAmount: 0,
          yesterdaySalesQuantity: 0,
          yesterdaySalesAmount: 0,
      },
      alertData: [
/*        {
          info: '预警通知内容预警通知内容预警通知内容预警通知内容预警通知内容',
          date:'2022/12/15 07:15',
        },
        {
          info: '预警通知内容预警通知内容预警通知内容预警通知内容预警通知内容',
          date:'2022/12/15 07:15',
        },
        {
          info: '预警通知内容预警通知内容预警通知内容预警通知内容预警通知内容',
          date:'2022/12/15 07:15',
        },
        {
          info: '预警通知内容预警通知内容预警通知内容预警通知内容预警通知内容',
          date:'2022/12/15 07:15',
        },
        {
          info: '预警通知内容预警通知内容预警通知内容预警通知内容预警通知内容',
          date:'2022/12/15 07:15',
        },
        {
          info: '预警通知内容预警通知内容预警通知内容预警通知内容预警通知内容',
          date:'2022/12/15 07:15',
        },*/
      ],
    }

  },
    created() {

    },
    destroyed() {
        clearInterval(this.refreshTime)
        clearInterval(this.timer)
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
  activated () {
      // 更新sessionStore 权限信息
      this.$store.dispatch('GetUserInfo')
      // 实时检测刷新token
      this.refreshToken()
      this.myDeviceApi()
      this.stockLogDetailApi()
      this.fetchJgList()
  },
  methods: {
    myDeviceApi(){
      myDeviceApi().then(resp => {
        if (resp.data.code === 0) {
            if(resp.data.data!=null){
              this.deviceMonitoringData=resp.data.data;
            }
        }
      }).catch(() => {

      })
    },
    stockLogDetailApi(){
      stockLogDetailApi(1).then(resp => {
        if (resp.data.code === 0) {
            if(resp.data.data!=null){
              this.receiveData=resp.data.data;
            }
        }
      }).catch(() => {

      })
    },
    fetchJgList(){
        fetchJgList().then(resp => {
        if (resp.data.code === 0) {
            if(resp.data.data!=null){
              this.alertData=resp.data.data.records;
              console.log(this.alertData)
            }
        }
    }).catch(() => {

    })
    },
    gotoChild () {
      this.$router.push({
        path: "/child"
      })
    },
    // 实时检测刷新token
    refreshToken() {
        this.refreshTime = setInterval(() => {
            checkToken(this.refreshLock, this.$store)
        }, 60000)
    }
  }
}
</script>

<style scoped lang="scss">
.index-section {
  height: 100%;
  width: 100%;
}

.header {
  height: 5%;
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
  padding-left: .5rem;
}

.up {
  width: 100%;
  height: 30%;
  background: linear-gradient(137deg, #5466FF 0%, #63A2FD 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-bottom: .32rem;

  .title {
    // width: 64px;
    // // height: 25px;
    font-size: .45rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    // line-height: 25px;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    position: absolute;
    top: .4rem;
    left: .6rem;
  }

  .up-container {
    height: 75%;
    width: 100%;
    // background-color: #5466FF;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: stretch;

    .card {
      width: 35%;
      height: 45%;
      background-color: #FFFFFF;
      border-radius: .21rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .card-img {
        width: 30%;
        height: 65%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }

      .card-text-num {
        display: flex;
        flex-direction: column;
        align-items: center;

        .num {
          font-size: .5rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #000000;
        }

        .text {
          font-size: .4rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #808080;
        }
      }

    }
  }
}

.up-container {
  .card1 {
    .card-img {
      background-image: url('../../assets/images/all-device.png');
    }
  }

  .card2 {
    .card-img {
      background-image: url('../../assets/images/online-device.png');
    }
  }

  .card3 {
    .card-img {
      background-image: url('../../assets/images/offline-device.png');
    }
  }

  .card4 {
    .card-img {
      background-image: url('../../assets/images/outoff-device.png');
    }
  }
}

.down {
  width: 100%;
  height: calc(65% + .3rem);
  background-color: #ffffff;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: .4rem;
  margin-top: -.3rem;
  position: relative;
  z-index: 9999;
  .card{
    width: 100%;
    height: 30%;
    border: 1px solid rgba(245,245,245,0.94);
    .title{
      height: 50%;
      // background-color: aliceblue;
      font-weight: bold;
      font-size: .5rem;
      display: flex;
      align-items: center;
      padding-left: .5rem;
    }
    .container{
      height: 40%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .item{
        width: 35%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .num span{
          font-size: .63rem;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #000000;
          line-height: 14px;
        }
        .num span:nth-child(2){
          font-size: .43rem;
          margin-left: .15rem;
        }
        .text{
          font-size: .43rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
          margin-top: .32rem;
        }
      }
    }
  }
  .card1{
    // background-color: #63A2FD;
  }
  .card2{
    // background-color: #5466FF;
  }
  .card3{
    height: 40%;
    .title{
      height: 24%;
      align-items: flex-end;
    }
    .container{
      flex-direction: column;
      overflow: auto;
      height: 76%;
      padding-top: 1.2rem;
      .row{
        width: 85%;
        height: 50%;
        // background-color: antiquewhite;
        display: flex;
        align-items: center;
        margin-top: .4rem;
      }
    }
    .alert-text{
      font-size: .35rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #4D4D4D;
    }
  }
}


</style>
