import axios from 'axios'
import {serialize} from '@/util'
import {getStore} from '@/util/store'
import NProgress from 'nprogress' // progress bar
import { Toast } from 'vant';
import errorCode from '@/const/errorCode'
import {Message} from 'element-ui'
import 'nprogress/nprogress.css'
import qs from 'qs'
import store from '@/store' // progress bar style
import { Dialog } from 'vant';
import router from "@/router";

axios.defaults.timeout = 30000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
  showSpinner: false
})

// HTTPrequest拦截
axios.interceptors.request.use(config => {
  NProgress.start() // start progress bar
  const TENANT_ID = getStore({name: 'tenantId'})
  const isToken = (config.headers || {}).isToken === false
  const token = store.getters.access_token
  if (token && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + token// token
  }
  if (TENANT_ID) {
    config.headers['TENANT-ID'] = TENANT_ID // 租户ID
  }

  // headers中配置serialize为true开启序列化
  if (config.method === 'post' && config.headers.serialize) {
    config.data = serialize(config.data)
    delete config.data.serialize
  }

  if (config.method === 'get') {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, {arrayFormat: 'repeat'})
    }
  }

  return config
}, error => {
  return Promise.reject(error)
})

// HTTPresponse拦截
axios.interceptors.response.use(res => {
  NProgress.done()
  const status = Number(res.status) || 200
  const message = res.data.msg || errorCode[status] || errorCode['default']
  // 后台定义 424 针对令牌过去的特殊响应码
  if (status === 424) {
    // eslint-disable-next-line no-unreachable
    router.push({
      path: '/login'
    });
    //提示
    return Toast.fail({
      message: "令牌状态已过期，请重新登录",
      position: 'top',
    });
    return
  }

  if (status !== 200 || res.data.code === 1) {
    Message({
      message: message,
      type: 'error'
    })

    // eslint-disable-next-line no-unreachable
    router.push({
      path: '/login'
    });

    //提示
    return Toast.fail({
      message: message,
      position: 'top',
      icon: 'close'
    });

  }
  return res
}, error => {
  // 处理 503 网络异常
  if (error.response.status === 503) {
    Message({
      message: error.response.data.msg,
      type: 'error'
    })
  }
  NProgress.done()
  return Promise.reject(new Error(error))
})

export default axios
