<template>
  <div class="task-section">
<!--    <div class="header">
      <div>X</div>
    </div>-->
    <!-- 搜索 -->
    <div class="search">
      <van-search v-model="device.deviceCode" shape="round" placeholder="请输入智能柜编号" />
      <van-button class="search-btn" round size="small" type="info" @click="myDeviceStockQueryApi()">搜索</van-button>
    </div>
    <!-- 卡片 -->
    <div class="card-container">
      <div class="card card1">
        <span>{{ deviceData.all }}</span>
        <span>在线设备</span>
      </div>
      <div class="card card2">
        <span>{{ deviceData.restock }}</span>
        <span>待补货设备</span>
      </div>
      <div class="card card3">
        <span>{{ deviceData.offline }}</span>
        <span>离线设备</span>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <div class="table-header">
        <van-row>
          <van-col span="9">
<!--            <van-checkbox v-model="checkedHeader"></van-checkbox>-->
            <span style="margin-left: .3rem;">
              智能柜编号
            </span>
          </van-col>
          <van-col span="11">智能柜名称</van-col>
          <van-col span="4">操作</van-col>
        </van-row>
      </div>
      <div class="table-body">
        <van-row v-for="(item, index) in deviceTableData" style="height: 8%; margin-top: .2rem;">
          <van-col span="9" style="text-align: center;">
<!--            <van-checkbox v-model="checkedHeader"></van-checkbox>-->
              <div style="overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                  <span class="alert-text" style="margin-left: .3rem;font-size: 15px;">{{ item.deviceCode }}</span>
              </div>
          </van-col>
          <van-col span="11" style="font-size: 15px;text-align: center;" >
              <div style="overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                  <span class="alert-text">{{ item.nickName }}</span>
              </div>
          </van-col>
          <van-col span="4" style="color: #F09A4E;" @click="go2KC(item)" v-if="item.masterId!=-1">库存</van-col>
          <van-col span="4" style="color: #F09A4E;" v-if="item.masterId==-1"></van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue"
import {checkToken} from '@/api/login'
import {mapState} from "vuex";
import {myDeviceStockApi} from "@/api/admin/user";
export default {
  name: '',
  components: {
    Header
  },
  data () {
    return {
      // 刷新token锁
      refreshLock: false,
      // 刷新token的时间
      refreshTime: '',
      // 计时器
      timer: '',
      deviceCode: null,
      device: {
          deviceCode: null,
      },
      deviceData: {
          all: 0,
          restock: 0,
          offline: 0
      },
      checkedHeader: false,
      deviceTableData: [
/*        {
          num: 'HP02124',
          name: 'XXXXXX',
        },
        {
          num: 'HP02124',
          name: 'XXXXXX',
        },
        {
          num: 'HP02124',
          name: 'XXXXXX',
        },
        {
          num: 'HP02124',
          name: 'XXXXXX',
        },
        {
          num: 'HP02124',
          name: 'XXXXXX',
        },
        {
          num: 'HP02124',
          name: 'XXXXXX',
        },
        {
          num: 'HP02124',
          name: 'XXXXXX',
        },
        {
          num: 'HP02124',
          name: 'XXXXXX',
        },*/
      ]
    };
  },
  activated () {
      // 更新sessionStore 权限信息
      this.$store.dispatch('GetUserInfo')
      // 实时检测刷新token
      this.refreshToken()
      this.myDeviceStockApi()
  },
  created() {
  },
  destroyed() {
      clearInterval(this.refreshTime)
      clearInterval(this.timer)
  },
  computed: {
      ...mapState({
          userInfo: (state) => state.user.userInfo,
      }),
  },
  methods: {
    myDeviceStockApi(){
        myDeviceStockApi().then(resp => {
          if (resp.data.code === 0) {
              if(resp.data.data!=null){
                  this.deviceData=resp.data.data;
                  this.deviceTableData=resp.data.data.devices;
              }
          }
        }).catch(() => {
        })
    },
    myDeviceStockQueryApi(){
      myDeviceStockApi(this.device).then(resp => {
          if (resp.data.code === 0) {
              if(resp.data.data!=null){
                  this.deviceData=resp.data.data;
                  this.deviceTableData=resp.data.data.devices;
              }
          }
      }).catch(() => {
      })
    },
    gotoChild () {
      this.$router.push({
        path: "/uuu"
      })
    },
    go2KC (item) {
      //查看库存详情携带参数跳转页面
      this.$router.push({
        path: "/repertory",
        query: {item:item}
      })
    },
    // 实时检测刷新token
    refreshToken() {
        this.refreshTime = setInterval(() => {
            checkToken(this.refreshLock, this.$store)
        }, 60000)
    }
  }
}
</script>
<style scoped lang="scss">
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* 设置内容的最大宽度 */
}

.task-section {
  height: 100%;
  width: 100%;
}

.header {
  height: 5%;
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
  padding-left: .5rem;
}

.search {
  height: 8%;
  width: 100%;
  // background-color: bisque;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-btn {
  width: 20%;
  margin-left: .0rem;
}

.card-container {
  width: 100%;
  height: 20%;
  // background-color: bisque;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .card {
    height: 55%;
    width: 25%;
    // background-color: blueviolet;
    border-radius: .2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #ffffff;
    font-size: .4rem;
    font-family: PingFang SC-Bold, PingFang SC;
  }

  .card1 {
    background: linear-gradient(137deg, #78FFEA 0%, #2EBCA6 100%);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }

  .card2 {
    background: linear-gradient(137deg, #FFD39B 0%, #ED9142 100%);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }

  .card3 {
    background: linear-gradient(137deg, #63A2FD 0%, #5466FF 100%);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }
}

.table {
  width: 100%;
  height: 67%;
  // background: aquamarine;
}

.table-header {
  width: 100%;
  height: 8%;
  margin: 0 auto;
  font-size: .5rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #263DC5;
  display: flex;
  background: rgba(19, 71, 255, 0.13);
  padding: 0 .5rem 0 .5rem;

}

.table-body {
  height: 92%;
  width: 100%;
  padding: 0 .5rem 0 .5rem;
  margin: 0 auto;
  overflow: scroll;
  font-size: .5rem;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #263DC5;
  display: flex;
  flex-direction: column;
}

/deep/ .van-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/deep/ .van-col {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

}
</style>
