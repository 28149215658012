<template>
    <div class="home-section">
        <div class="tab-page cant-tab-page">
            <!-- <transition name="page-switch-fade"> -->
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
            <!-- </transition> -->
        </div>
        <van-tabbar
            class="tab-bar"
            v-model="$store.state.home.active"
            @change="tabChange"
        >
            <van-tabbar-item name="/home/index">
                <span>商户首页</span>
                <template #icon="props">
                    <img v-if="props.active" src="../../assets/images/tab-home-active.png" />
                    <img v-else src="../../assets/images/tab-home.png" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/home/task">
                <span>设备库存</span>
                <template #icon="props">
                    <img v-if="props.active" src="../../assets/images/tab-device-active.png" />
                    <img v-else src="../../assets/images/tab-device.png" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/home/scene">
                <span>数据统计</span>
                <template #icon="props">
                    <img v-if="props.active" src="../../assets/images/tab-data-active.png" />
                    <img v-else src="../../assets/images/tab-data.png" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item name="/home/mine">
                <span>个人中心</span>
                <template #icon="props">
                    <img v-if="props.active" src="../../assets/images/tab-my-active.png" />
                    <img v-else src="../../assets/images/tab-my.png" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import {checkToken} from '@/api/login'
import {mapState} from "vuex";
export default {
    name:'',
    components: {},
    data() {
        return {
            // 刷新token锁
            refreshLock: false,
            // 刷新token的时间
            refreshTime: '',
            // 计时器
            timer: ''
        }
    },
    activated(){
        // 更新sessionStore 权限信息
        this.$store.dispatch('GetUserInfo')
        // 实时检测刷新token
        this.refreshToken()
    },
    created() {

    },
    destroyed() {
        clearInterval(this.refreshTime)
        clearInterval(this.timer)
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
    methods: {
        tabChange(active){
            console.log(this.userInfo)

            const router = this.$router
            // this.$store.commit("changeTabActive",active)
            router.push({
                path:active
            })
        },
        // 实时检测刷新token
        refreshToken() {
            this.refreshTime = setInterval(() => {
                checkToken(this.refreshLock, this.$store)
            }, 60000)
        }
    }
}
</script>
<style scoped lang="scss">
    .home-section{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .tab-page{
            &.cant-tab-page{
                height: calc(100% - 50px);
                overflow: hidden;
            }
        }
        .tab-bar{
            position: relative;
        }
    }
</style>
