<template>
  <div class="scene-section">
<!--    <div class="header">
      <div>X</div>
    </div>-->
    <!-- 领用统计 -->
    <div class="card card1">
      <div class="title">领用统计</div>
      <div class="container">
        <div class="item item1">
          <span class="num">{{ receiveData.totalQuantity }}</span>
          <span class="text">今日领用量</span>
          <span class="text">（个）</span>
        </div>
        <div class="item item2">
          <span class="num">{{ receiveData.totalAmount }}</span>
          <span class="text">今日领用收益</span>
          <span class="text">（元）</span>
        </div>
        <div class="item item3">
          <span class="num">{{ receiveData.yesterdaySalesQuantity }}</span>
          <span class="text">昨日领用量</span>
          <span class="text">（个）</span>
        </div>
        <div class="item item4">
          <span class="num">{{ receiveData.yesterdaySalesAmount }}</span>
          <span class="text">昨日领用收益</span>
          <span class="text">（元）</span>
        </div>
      </div>
    </div>
    <!-- 补货统计 -->
    <div class="card card2">
      <div class="title">补货统计</div>
      <div class="container">
        <div class="item item1">
          <span class="num">{{ receiveData1.totalQuantity }}</span>
          <span class="text">今日补货量</span>
          <span class="text">（个）</span>
        </div>
        <div class="item item2">
          <span class="num">{{ receiveData1.totalQuantity }}</span>
          <span class="text">今日补货支出</span>
          <span class="text">（元）</span>
        </div>
        <div class="item item3">
          <span class="num">{{ receiveData1.totalQuantity }}</span>
          <span class="text">昨日补货量</span>
          <span class="text">（个）</span>
        </div>
        <div class="item item4">
          <span class="num">{{ receiveData1.totalQuantity }}</span>
          <span class="text">昨日补货支出</span>
          <span class="text">（元）</span>
        </div>
      </div>
    </div>
    <!-- 领用趋势图 -->
    <div class="card card3">
      <div class="title">领用趋势图</div>
      <div class="container" id="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import {checkToken} from '@/api/login'
import {mapState} from "vuex";
import { LineChart } from 'echarts';
import Header from "../../components/header.vue"
import {stockLogDetailApi,stockLogDetailWeekApi} from "@/api/admin/user";
export default {
  name: '',
  components: {
    Header
  },
  data () {
    return {
        // 刷新token锁
        refreshLock: false,
        // 刷新token的时间
        refreshTime: '',
        nums: null,
        // 计时器
        timer: '',
      myChart: null,
        receiveData: {
            totalQuantity: 0,
            totalAmount: 0,
            yesterdaySalesQuantity: 0,
            yesterdaySalesAmount: 0,
        },
        receiveData1: {
            totalQuantity: 0,
            totalAmount: 0,
            yesterdaySalesQuantity: 0,
            yesterdaySalesAmount: 0,
        },
    };
  },
  mounted () {
    this.chartDraw();
  },
  activated () {
      // 更新sessionStore 权限信息
      this.$store.dispatch('GetUserInfo')
      // 实时检测刷新token
      this.refreshToken()
      this.stockLogDetailApi()
      this.chartDraw()
  },
  created() {

  },
  destroyed() {
      clearInterval(this.refreshTime)
      clearInterval(this.timer)
  },
  computed: {
      ...mapState({
          userInfo: (state) => state.user.userInfo,
      }),
  },
  methods: {
      stockLogDetailApi(){
          stockLogDetailApi(1).then(resp => {
            if (resp.data.code === 0) {
                if(resp.data.data!=null){
                    this.receiveData=resp.data.data;
                }
            }
          }).catch(() => {

          })
          stockLogDetailApi(2).then(resp => {
            if (resp.data.code === 0) {
                if(resp.data.data!=null){
                    this.receiveData1=resp.data.data;
                }
            }
          }).catch(() => {

          })
      },
    chartDraw () {
    stockLogDetailWeekApi(1).then(resp => {
      if (resp.data.code === 0) {
       if(resp.data.data!=null){
          const arr = resp.data.data.map(item => item.num);
          this.nums = Array.from(arr.map(num => parseInt(num)));
          const newArray = Array.from(this.nums);
          this.myChart = echarts.init(document.getElementById('chart'));
          let xAxisData = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
          this.myChart.setOption({
            grid: {
                top: '10%',//上边距
                right: '6%',//右边距
                left: '6%',//左边距
                bottom: '10%',//下边距
                containLabel: true,
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: xAxisData,
              splitLine: { show: false, lineStyle: { type: 'dashed' } },
              axisTick: { show: false },
              axisLabel: { show: true }
            },
            yAxis: {
              type: 'value',
              boundaryGap: ['0%', '20%'],
              alignTicks: true,
              splitNumber: 5,
              nameTextStyle: {
                color: '#ffffff',
                fontSize: 13,
                fontFamily: 'Source Han Sans CN-Normal',
                align: "left",
                verticalAlign: "center",
              },
              axisLabel: {
                color: '#717171',
                fontSize: 12,
                fontFamily: 'Source Han Sans CN-Normal',
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false
              },
              splitLine: {
                lineStyle: {
                  color: 'rgba(0, 0, 0, 0.2)',
                  type: "solid",
                },
                show: true
              }
            },
            series: [
              {
                data: newArray,
                type: 'line',
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {
                        offset: 0,
                        color: '#91ABFF'
                      },
                      {
                        offset: 0.1,
                        color: '#95AEFD'
                      },
                      {
                        offset: 0.2,
                        color: '#A3BAFF'
                      },
                      {
                        offset: 0.3,
                        color: '#AEC3FF'
                      },
                      {
                        offset: 0.4,
                        color: '#B7CAFF'
                      },
                      {
                        offset: 0.5,
                        color: '#BBCEFF'
                      },
                      {
                        offset: 0.6,
                        color: '#C5D6FF'
                      },
                      {
                        offset: 0.7,
                        color: '#CDDDFF'
                      },
                      {
                        offset: 0.8,
                        color: '#D0DEFF'
                      },
                      {
                        offset: 0.9,
                        color: '#DAE7FF'
                      },
                      {
                        offset: 1,
                        color: '#DFEBFF'
                      }
                    ],
                    false
                  )
                },
                smooth: true,
                showSymbol : false,
              }
            ],
            tooltip: {
              // 设置提升效果
              trigger: 'axis',
              backgroundColor: '#68CEFF'
            }
      });
    }else{
           this.myChart = echarts.init(document.getElementById('chart'));
           let xAxisData = ['周一', '周二', '周三', '周四', '周五', '周六', '周日'];
           this.myChart.setOption({
               grid: {
                   top: '10%',//上边距
                   right: '6%',//右边距
                   left: '6%',//左边距
                   bottom: '10%',//下边距
                   containLabel: true,
               },
               xAxis: {
                   type: 'category',
                   boundaryGap: false,
                   data: xAxisData,
                   splitLine: { show: false, lineStyle: { type: 'dashed' } },
                   axisTick: { show: false },
                   axisLabel: { show: true }
               },
               yAxis: {
                   type: 'value',
                   boundaryGap: ['0%', '20%'],
                   alignTicks: true,
                   splitNumber: 5,
                   nameTextStyle: {
                       color: '#ffffff',
                       fontSize: 13,
                       fontFamily: 'Source Han Sans CN-Normal',
                       align: "left",
                       verticalAlign: "center",
                   },
                   axisLabel: {
                       color: '#717171',
                       fontSize: 12,
                       fontFamily: 'Source Han Sans CN-Normal',
                   },
                   axisLine: {
                       show: false,
                   },
                   axisTick: {
                       show: false
                   },
                   splitLine: {
                       lineStyle: {
                           color: 'rgba(0, 0, 0, 0.2)',
                           type: "solid",
                       },
                       show: true
                   }
               },
               series: [
                   {
                       data: [0,0,0,0,0,0,0],
                       type: 'line',
                       areaStyle: {
                           color: new echarts.graphic.LinearGradient(
                               0, 0, 0, 1,
                               [
                                   {
                                       offset: 0,
                                       color: '#91ABFF'
                                   },
                                   {
                                       offset: 0.1,
                                       color: '#95AEFD'
                                   },
                                   {
                                       offset: 0.2,
                                       color: '#A3BAFF'
                                   },
                                   {
                                       offset: 0.3,
                                       color: '#AEC3FF'
                                   },
                                   {
                                       offset: 0.4,
                                       color: '#B7CAFF'
                                   },
                                   {
                                       offset: 0.5,
                                       color: '#BBCEFF'
                                   },
                                   {
                                       offset: 0.6,
                                       color: '#C5D6FF'
                                   },
                                   {
                                       offset: 0.7,
                                       color: '#CDDDFF'
                                   },
                                   {
                                       offset: 0.8,
                                       color: '#D0DEFF'
                                   },
                                   {
                                       offset: 0.9,
                                       color: '#DAE7FF'
                                   },
                                   {
                                       offset: 1,
                                       color: '#DFEBFF'
                                   }
                               ],
                               false
                           )
                       },
                       smooth: true,
                       showSymbol : false,
                   }
               ],
               tooltip: {
                   // 设置提升效果
                   trigger: 'axis',
                   backgroundColor: '#68CEFF'
               }
           });
       }
  }
}).catch(() => {
})
    },
    // 实时检测刷新token
    refreshToken() {
        this.refreshTime = setInterval(() => {
            checkToken(this.refreshLock, this.$store)
        }, 60000)
    }
  },
}
</script>
<style scoped lang="scss">
.scene-section {
  height: 100%;
  width: 100%;
}

.header {
  height: 5%;
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
  padding-left: .5rem;
}

.card {
  height: 23%;
  width: 100%;
  border-bottom: 1px solid #F0F0F0;

  .title {
    height: 30%;
    // background-color: burlywood;
    font-size: .45rem;
    display: flex;
    // align-items: center;
    align-items: flex-end;
    padding-left: .5rem;
    // width: 64px;
    // height: 25px;
    // font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #141414;
    // line-height: 25px;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 70%;

    .item {
      // background-color: chartreuse;
      height: 50%;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .num {
        font-size: .45rem;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #000000;
      }

      .text {
        font-size: .3rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #717171;
      }
    }
  }
}

.card3 {
  height: 49%;
  // background-color: aqua;

  .title {
    height: 13%;
    width: 100%;
  }

  .container {
    width: 100%;
    height: 60%;
    // background-color: burlywood;
  }
}
</style>
